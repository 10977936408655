import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
import { Link } from "gatsby"
import SEO from "../components/seo"
import helpers from "../scripts/helpers"

import cssStyle from "../styles/errorpage.css"

class Erro404 extends React.Component {
  render = () => (
    <React.Fragment>
      <SEO title="404: Not found" />
      <h1>Erreur 404</h1>
      <p className="zoom-area">
        <b>CSS</b> Toutes nos excuses, mais votre requête n'a donné aucun résultat.<br />
        Peut-être qu'une recherche peut vous indiquer un article liè.
      </p>
      <section className="error-container">
        <span className="four"><span className="screen-reader-text">4</span></span>
        <span className="zero"><span className="screen-reader-text">0</span></span>
        <span className="four"><span className="screen-reader-text">4</span></span>
      </section>
      <div className="link-container">
        <Link target="_blank" to="/" className="more-link">
        Accueil
      </Link>
      </div>
    </React.Fragment>
  )

  componentDidMount = () => {
    helpers.loadCSS(cssStyle, "#errorpage")
  }

  componentWillUnmount = () => {
    helpers.unloadCSS("#errorpage")
  }

}

export default Erro404
